import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Icons } from '../Icons';

const Skills = () => {
    return (
        <>
            <Row>
                <Col className='nopadding'>
                    <div className='SectionDWork bp-30' id='Skills'>
                        <div className='container'>
                            <div className='pageTopSection'>
                                <div className='headingDark mt-40' >Skills</div>
                                <div>
                                    <div className='navRightCont'>
                                        <ul className='topMeu light'>
                                            <li><a href='#About'>About</a></li>
                                            <li><a href='#Experience'>Experience</a></li>
                                            <li><a href='#Work'>Work</a></li>
                                            <li><a href='#Skills'>Skills</a></li>
                                        </ul>
                                        <div className='ml-40'><a href='#talk'>{Icons.LetsTalk}</a></div>
                                    </div>
                                </div>
                            </div>
                            <div className='skillsWrapper mt-40'>
                                <div className='yearExpCont mt-50'>
                                    <div className='expCount'>09</div>
                                    <div className='expLable'>Years Of Experience</div>
                                    <div className='skillListCont'>
                                        <img src="./assets/html.png" alt="" />
                                        <img src="./assets/css.png" alt="" />
                                        <img src="./assets/bootstrap.png" alt="" />
                                        <img src="./assets/js.png" alt="" />
                                    </div>
                                </div>
                                <div className='rightSkillWrapper'>
                                    <div className='skillsCont'>
                                        <div className='skillsItemContXD'>
                                            <div className='skillsCont1'>
                                                <div className='svg'>{Icons.XD}</div>
                                                <div className='XD'>Adobe XD</div>
                                            </div>
                                        </div>
                                        <div className='skillsItemContFigma'>
                                            <div className='skillsCont1'>
                                                <div className='svg'>{Icons.Figma}</div>
                                                <div className='Figma'>Figma</div>
                                            </div>
                                        </div>
                                        <div className='skillsItemContPS'>
                                            <div className='skillsCont1'>
                                                <div className='svg'>{Icons.PS}</div>
                                                <div className='ps'>Photoshop</div>
                                            </div>
                                        </div>
                                        <div className='skillsItemContAI'>
                                            <div className='skillsCont1'>
                                                <div className='svg'>{Icons.AI}</div>
                                                <div className='ai'>Illustrator</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='skillsCont mt-40'>
                                        <div className='skillsItemContBlender'>
                                            <div className='skillsCont1'>
                                                <div className='svg'>{Icons.Blender}</div>
                                                <div className='blender'>Blender</div>
                                            </div>
                                        </div>
                                        <div className='skillsItemContCorel'>
                                            <div className='skillsCont1'>
                                                <div className='svg'><img src="./assets/corel.png" alt="" /></div>
                                                <div className='corel'>CrolDRAW</div>
                                            </div>
                                        </div>
                                        <div className='toolBarCont ml-40'>
                                            ToolBox
                                        </div>
                                    </div>
                                    <div className='mt-40 ml-25'>
                                        <div className='otherSkills mb-20 mr-15'>Prototyping</div>
                                        <div className='otherSkills mb-20 mr-15'>Wireframing</div>
                                        <div className='otherSkills mb-20 mr-15'>Visual Design</div>
                                        <div className='otherSkills mb-20 mr-15'>User Research</div>
                                        <div className='otherSkills mb-20 mr-15'>Typography</div>
                                        <div className='otherSkills mb-20 mr-15'>Interaction Design</div>
                                    </div>
                                </div>
                            </div>
                            <div className='socialLinkCont mt-150' id='talk'>
                                <div className='socialLink'>
                                    <a href="https://dribbble.com/KamalCreatives" target="_blank" rel="noreferrer">Dribbble <span>{Icons.SocialLink}</span></a></div>
                                <div className='socialLink'>
                                    <a href="https://www.behance.net/kamalcreatives" target="_blank" rel="noreferrer">Behance <span>{Icons.SocialLink}</span></a>
                                </div>
                                <div className='socialLink'>
                                    <a href="https://www.linkedin.com/in/shoebkamal/" target="_blank" rel="noreferrer">linkedin <span>{Icons.SocialLink}</span></a>
                                </div>
                            </div>

                            <div className='contactCont mt-150'>
                                <div className='talk'>Let's Talk</div>
                                <div className='talkLable mt-10'>I'm Available For Small And Short-Term Projects.</div>
                                <div className='talkEmail mt-70'><a href="mailto:kamaalshoeb@gmail.com">kamaalshoeb@gmail.com</a></div>
                                <div className='talkEmail mt-10'>+91 99292 77633</div>
                                <div className='mt-70'>
                                    <div>{Icons.Heart}</div>
                                    <div className='thanksVisit mt-10'>Thank You For Visiting</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default Skills;