import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Icons } from '../Icons';

const Expertise = () => {
    return (
        <>
            <Row>
                <Col className='nopadding'>
                    <div className='SectionL'>
                        <div className='container'>
                            <div className='exptiseCont'>
                                <div>
                                    <div className='expertiseHeading1'>Design</div>
                                    <div className='expertiseHeading2'>World For</div>
                                    <div className='expertiseHeading3'>UI/UX</div>
                                </div>
                                <div>
                                    <div className='expertiseKeyCont'>
                                        <div className='mr-20'>{Icons.UIDesign}</div>
                                        <div className='expertiseKeyHeading'>UI/UX Design</div>
                                    </div>
                                    <div className='expertiseKeyCont'>
                                        <div className='mr-20'>{Icons.GraphicDesign}</div>
                                        <div className='expertiseKeyHeading'>Graphic Design</div>
                                    </div>
                                    <div className='expertiseKeyCont'>
                                        <div className='mr-20'>{Icons.MobileDesign}</div>
                                        <div className='expertiseKeyHeading'>Mobile Application</div>
                                    </div>
                                    <div className='expertiseKeyCont'>
                                        <div className='mr-20'>{Icons.WebDesign}</div>
                                        <div className='expertiseKeyHeading'>Web Application</div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default Expertise;