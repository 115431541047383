import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Icons } from '../Icons';

const TopSection = () => {
    return (
        <>
            <Row >
                <Col className='nopadding'>
                    <div className='SectionL'>
                        <div className='scrollDownCont'>
                            <div className='mr-5 bounce'>{Icons.DownArrow}</div>
                            <div className='scrollText'>Scroll</div>
                        </div>
                        <div className='container'>
                            <div className='navSection mt-30'>
                                <div className='logoSection'>
                                    <div className='mr-10'><img src='./assets/profile.png' alt='' /></div>
                                    <div className='logoName'>Shoeb Kamal</div>
                                </div>
                                <div className='navRightCont'>
                                    <div>
                                        <ul className='topMeu'>
                                            <li><a href='#About'>About</a></li>
                                            <li><a href='#Experience'>Experience</a></li>
                                            <li><a href='#Work'>Work</a></li>
                                            <li><a href='#Skills'>Skills</a></li>
                                        </ul>
                                    </div>
                                    <div className='ml-40'><a href='#talk'>{Icons.LetsTalk}</a></div>
                                </div>
                            </div>

                            <di className='bannerCont'>
                                <div className='Flex'>
                                    <div className='headerRowA'>
                                        <div className='headingA mr-10'>UI/UX</div>
                                        <div className='lableA'>and</div>
                                    </div>
                                    <div className='headingBFlex'>
                                        <div></div>
                                        <div className='headingB'>Web Designer</div>
                                    </div>
                                </div>
                            </di>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default TopSection;