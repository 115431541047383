import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { Icons } from '../Icons';

function About() {
    return (
        <>
            <Row>
                <Col className='nopadding'>
                    <div className='SectionD' id='About'>
                        {/* <div className='scrollDownCont'>
                            <div className='mr-5 bounce'>{Icons.DownArrowL}</div>
                            <div className='scrollTextL'>Scroll</div>
                        </div> */}
                        <div className='container' >
                            <div className='pageTopSection'>
                                <div className='headingDark mt-30' >About</div>
                                <div>
                                    <div className='navRightCont'>
                                        <div>
                                            <ul className='topMeu light'>
                                                <li><a href='#About'>About</a></li>
                                                <li><a href='#Experience'>Experience</a></li>
                                                <li><a href='#Work'>Work</a></li>
                                                <li><a href='#Skills'>Skills</a></li>
                                            </ul>
                                        </div>
                                        <div className='ml-40'><a href='#talk'>{Icons.LetsTalk}</a></div>
                                    </div>
                                </div>
                            </div>
                            <div className='aboutCont'>
                                <div>
                                    <div className='aboutName'>Shoeb Kamal</div>
                                    <div className='aboutPos'>UI/UX Designer & Frontend Developer</div>
                                    <div className='mt-30'>
                                        <Button className='DownloadCV'>Download CV</Button>
                                    </div>
                                </div>
                                <div className='aboutNameSec'>
                                    <div>
                                        <div className='aboutDes'>Hello there!  I am a UI/UX Designer, Frontend Developer, and 3D artist. I'm very passionate and dedicated to my work. I love music, color palette, and playing games.</div>
                                        <div className='aboutDes mt-30'>With 9 years of experience as a professional UI/UX Designer, I have acquired the skills and knowledge necessary to make your project a success.</div>
                                    </div>
                                    <div className='otherDetailCont mt-70'>
                                        <div className='otherGrid'>
                                            <div className='otherLabel'>Age</div>
                                            <div className='otherValue'>34</div>
                                        </div>
                                        <div className='otherGrid'>
                                            <div className='otherLabel'>Location</div>
                                            <div className='otherValue'>Jodhpur, Rajasthan</div>
                                        </div>
                                    </div>
                                    <div className='otherDetailCont mt-70'>
                                        <div className='otherGrid'>
                                            <div className='otherLabel'>Contact Number</div>
                                            <div className='otherValue'>+91 99292 77633</div>
                                        </div>
                                        <div className='otherGrid'>
                                            <div className='otherLabel'>Email Address</div>
                                            <div className='otherValue'>kamaalshoeb@gmail.com</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default About;