import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import About from './Components/About';
import Experience from './Components/Experience';
import Expertise from './Components/Expertise';
import Skills from './Components/Skills';
import TopSection from './Components/TopSection';
import Work from './Components/Work';
import { Icons } from './Icons';

const Landing = () => {
    const [visible, setvisible] = useState(false);
    useEffect(() => {
        window.addEventListener('scroll', listToScroll);
    },);

    const listToScroll = () => {
        let heightToHide = 250;
        const winSroll = document.body.scrollTop || document.documentElement.scrollTop;
        if (winSroll > heightToHide) {
            setvisible(true);
        } else {
            setvisible(false);
        }
        console.log(winSroll);
    };

    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });

    };
    return (
        <>
            {visible &&
                <div className='scrollTopCont' onClick={() => scrollTop()}>
                    <div>
                        {Icons.ScrollUp}
                    </div>
                </div>
            }
            <Container fluid>
                <TopSection />
                <About />
                <Experience />
                <Work />
                <Expertise />
                <Skills />
            </Container>
        </>
    );
};

export default Landing;