import React from 'react';
import { Row, Col, } from 'react-bootstrap';
import { Icons } from '../Icons';

function Experience() {
    return (
        <>
            <Row>
                <Col className='nopadding'>
                    <div className='SectionL' id='Experience'>
                        <div className='container'>
                            <div className='pageTopSection'>
                                <div>
                                    <div className='headingLight mt-20' >Experience</div>
                                </div>
                                <div>
                                    <div className='navRightCont'>
                                        <div>
                                            <ul className='topMeu'>
                                                <li><a href='#About'>About</a></li>
                                                <li><a href='#Experience'>Experience</a></li>
                                                <li><a href='#Work'>Work</a></li>
                                                <li><a href='#Skills'>Skills</a></li>
                                            </ul>
                                        </div>
                                        <div className='ml-40'><a href='#talk'>{Icons.LetsTalk}</a></div>
                                    </div>
                                </div>
                            </div>
                            <div className='expCont'>
                                <div className='workWrapper'>
                                    <div className='expSectionHeading'>Work</div>
                                    <div className='expListWrapper mt-30'>

                                        <div className='expBulletWrapper mr-20'>
                                            <div className='expWorkBulletCont'></div>
                                            <div className='expBulletPointTop'><div></div></div>
                                            <div className='expBulletPointBottom'><div></div></div>
                                            <div className='expBulletPointMiddle'><div></div></div>
                                        </div>

                                        <div>
                                            <div className='expItemCont'>
                                                <div className='expLabel'>2021 - Present</div>
                                                <div className='expHeading'>AppsDelegate Technologies</div>
                                                <div className='expPara mt-05'>I am responsible for making interactive UI/UX and visually appealing. Along with that converting to HTML, JavaScript, and CSS</div>
                                            </div>
                                            <div className='expItemCont mt-40'>
                                                <div className='expLabel'>2012 - 2020 (8 Years)</div>
                                                <div className='expHeading'>Matellio India Pvt. Ltd</div>
                                                <div className='expPara mt-05'>Work with other developers to ensure that the website is functional and visually appealing. Ensure that the website is optimised for various devices.</div>
                                            </div>
                                            <div className='expItemCont mt-40'>
                                                <div className='expLabel'>2011 - 2012 (Internship)</div>
                                                <div className='expHeading'>Dreamteam Technologies Pvt Ltd</div>
                                                <div className='expPara mt-05'>I work here to convert PSD into HTML, or CSS code to build the core elements of Front-End Development, Focusing on creating an excellent user experience.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='educationWrapper'>
                                    <div className='expSectionHeading'>Education</div>
                                    <div className='expListWrapper mt-30'>

                                        <div className='eduBulletWrapper mr-20'>
                                            <div className='eduWorkBulletCont'></div>
                                            <div className='expBulletPointTop'><div></div></div>
                                            <div className='expBulletPointBottom'><div></div></div>
                                        </div>

                                        <div>
                                            <div className='expItemCont'>
                                                <div className='expLabel'>High School Graduate</div>
                                                <div className='expHeading'>Lucky Bal Niketan</div>
                                                <div className='expPara mt-05'>jodhpur, Rajasthan</div>
                                            </div>
                                            <div className='expItemCont mt-40'>
                                                <div className='expLabel'>Graduation Degree</div>
                                                <div className='expHeading'>Bachelor In Computer Application (BCA)</div>
                                                <div className='expPara mt-05'>from Aishwarya College of Education </div>
                                                <div className='expPara mt-05'>jodhpur, Rajasthan</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default Experience;