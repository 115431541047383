import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { Icons } from '../Icons';

const Work = () => {
    return (
        <>
            <Row>
                <Col className='nopadding'>
                    <div className='SectionDWork' id='Work'>
                        <div className='container'>
                            <div className='pageTopSection'>
                                <div className='headingDark mt-30' >Work</div>
                                <div>
                                    <div className='navRightCont'>
                                        <ul className='topMeu light'>
                                            <li><a href='#About'>About</a></li>
                                            <li><a href='#Experience'>Experience</a></li>
                                            <li><a href='#Work'>Work</a></li>
                                            <li><a href='#Skills'>Skills</a></li>
                                        </ul>
                                        <div className='ml-40'><a href='#talk'>{Icons.LetsTalk}</a></div>
                                    </div>
                                </div>
                            </div>

                            <div className='workCategoryCont'>
                                <ul className='workCategoryMenu'>
                                    <li>All</li>
                                    <li>Web</li>
                                    <li>Mobile</li>
                                    <li>3D Artwok</li>
                                </ul>

                            </div>
                            {/* <div>
                                    <ul className='workCategoryMenu'>
                                        <li>All</li>
                                        <li>Web</li>
                                        <li>Mobile</li>
                                        <li>3D Artwok</li>
                                    </ul>
                                </div> */}
                            <div className='workCardWrapper mt-40'>
                                <div className='workCardItemCont'>
                                    <div className='workCardTopSection'>
                                        <div>
                                            <div className='workHeading'>Online Education Web Design</div>
                                            <div className='workCategory mt-05'>Web Application</div>
                                        </div>
                                        <div className='workOpenIconCont'>{Icons.OpenIcon}</div>
                                    </div>
                                    <div className='workImageCont'><img src="./assets/web01.png" alt="" /></div>
                                </div>
                                <div className='workCardItemCont'>
                                    <div className='workCardTopSection'>
                                        <div>
                                            <div className='workHeading'>Retro Star 3D Cinema Theater</div>
                                            <div className='workCategory mt-05'>3D Artwork</div>
                                        </div>
                                        <div className='workOpenIconCont'>{Icons.OpenIcon}</div>
                                    </div>
                                    <div className='workImageCont'><img src="./assets/3D01.png" alt="" /></div>
                                </div>
                                <div className='workCardItemCont'>
                                    <div className='workCardTopSection'>
                                        <div>
                                            <div className='workHeading'>Travel Service - Mobile App</div>
                                            <div className='workCategory mt-05'>Mobile Application</div>
                                        </div>
                                        <div className='workOpenIconCont'>{Icons.OpenIcon}</div>
                                    </div>
                                    <div className='workImageCont'><img src="./assets/app01.png" alt="" /></div>
                                </div>
                                <div className='workCardItemCont'>
                                    <div className='workCardTopSection'>
                                        <div>
                                            <div className='workHeading'>Home Theater Isometric </div>
                                            <div className='workCategory mt-05'>3D Artwork</div>
                                        </div>
                                        <div className='workOpenIconCont'>{Icons.OpenIcon}</div>
                                    </div>
                                    <div className='workImageCont'><img src="./assets/3D02.png" alt="" /></div>
                                </div>
                                <div className='workCardItemCont'>
                                    <div className='workCardTopSection'>
                                        <div>
                                            <div className='workHeading'>Healthcare Service</div>
                                            <div className='workCategory mt-05'>Mobile Application</div>
                                        </div>
                                        <div className='workOpenIconCont'>{Icons.OpenIcon}</div>
                                    </div>
                                    <div className='workImageCont'><img src="./assets/app02.png" alt="" /></div>
                                </div>
                                <div className='workCardItemCont'>
                                    <div className='workCardTopSection'>
                                        <div>
                                            <div className='workHeading'>Travelling Web App</div>
                                            <div className='workCategory mt-05'>Web Application</div>
                                        </div>
                                        <div className='workOpenIconCont'>{Icons.OpenIcon}</div>
                                    </div>
                                    <div className='workImageCont'><img src="./assets/web02.png" alt="" /></div>
                                </div>
                            </div>
                            <div className='loadMoreCont'><Button className='loadMoreBTN'>Load More</Button></div>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default Work;