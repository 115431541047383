import React from 'react';
import Landing from './Landing';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Style.css';
import './Animation.css';
import { Route, Routes } from 'react-router-dom';


const App = () => {
    return (
        <>
            <Routes>
                <Route exact path='/' element={<Landing />}></Route>
            </Routes>
        </>
    );
};

export default App;